<div [ngClass]="{ loading: !paymentProviderDetails }">
  <ni-loader *ngIf="!paymentProviderDetails" [isLoading]="true"></ni-loader>
  <ng-container *ngIf="paymentProviderDetails">
    <ni-system-feedback
      *ngIf="modalData.displayPaymentError"
      [feedbackType]="FeedbackType.Error"
      class="system-feedback"
      data-test="error-card"
    >
      {{ 'PAYMENT.ERRORS.PROCESSING_PAYMENT_ERROR' | translate }}
    </ni-system-feedback>
    <div class="payment-form-container" *ngIf="policy$ | async as policy">
      <ng-container *ngIf="selectedPaymentMethod">
        <div
          *ngIf="paymentMethodTypeOptions.length > 1"
          class="payment-method-details-container"
          data-test="payment-method-type-buttons-container"
        >
          <ni-payment-method-type-buttons [methodTypes]="paymentMethodTypeOptions" (methodTypeSelected)="onMethodTypeSelected($event)">
          </ni-payment-method-type-buttons>
        </div>
        <div [hidden]="selectedPaymentMethod !== PaymentMethodType.CreditCard" data-test="credit-card-container">
          <ni-stripe-credit-card-form
            data-test="stripe-credit-card-form"
            *ngIf="paymentProviderDetails.allowedMethods.CREDIT_CARD?.provider === PaymentProviderEnum.Stripe"
            [accessToken]="paymentProviderDetails.allowedMethods.CREDIT_CARD?.accessToken"
            [providerRequestTokenId]="paymentProviderDetails.allowedMethods.CREDIT_CARD?.providerRequestTokenId"
            (submitted)="onSubmitStripeCreditCard($event, policy)"
            (error)="onErrorOccurredDuringUpdatePayment($event)"
            [carrier]="policy.carrierId"
            [submitText]="'GENERAL.CONFIRM'"
            [isSubmitting]="(modalData.isSubmittingAction$ && modalData.isSubmittingAction$ | async) || (isPaymentSubmitting$ | async)"
          ></ni-stripe-credit-card-form>
          <ni-braintree-payment-form
            data-test="braintree-credit-card-form"
            *ngIf="paymentProviderDetails.allowedMethods.CREDIT_CARD?.provider === PaymentProviderEnum.Braintree"
            [providerToken]="paymentProviderDetails.allowedMethods.CREDIT_CARD?.accessToken"
            (submitted)="onSubmitBraintreeCreditCard($event, policy)"
            (error)="onErrorOccurredDuringUpdatePayment($event)"
            [isSubmitting]="(modalData.isSubmittingAction$ && modalData.isSubmittingAction$ | async) || (isPaymentSubmitting$ | async)"
            [isSubmitDisabled]="shouldDisableButton$ | async"
          ></ni-braintree-payment-form>
        </div>
        <div [hidden]="selectedPaymentMethod !== PaymentMethodType.BankAccount" data-test="bank-account-container">
          <ni-stripe-ach-payment-form
            *ngIf="paymentProviderDetails.allowedMethods.BANK_ACCOUNT?.provider === PaymentProviderEnum.Stripe"
            data-test="stripe-ach-form"
            [shouldDisplayTitle]="paymentMethodTypeOptions.length == 1"
            [accessToken]="paymentProviderDetails.allowedMethods.BANK_ACCOUNT.accessToken"
            [providerRequestTokenId]="paymentProviderDetails.allowedMethods.BANK_ACCOUNT.providerRequestTokenId"
            (submitted)="onSubmitStripeACH($event, policy)"
            (error)="onErrorOccurredDuringUpdatePayment($event)"
            [isSubmitting]="(modalData.isSubmittingAction$ && modalData.isSubmittingAction$ | async) || (isPaymentSubmitting$ | async)"
          >
          </ni-stripe-ach-payment-form>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
