import { Action, createReducer, on } from '@ngrx/store';

import { HolidayTheme } from '../models/holiday-themes.model';
import { UserType } from '../models/user-type.enum';
import { coreActions } from './core.actions';

export interface CoreState {
  isLoading: boolean;
  isFooterVisible: boolean;
  isNavigationTabsVisible: boolean;
  user: {
    isUserLoggedIn: boolean;
    userType: UserType;
  };
  showCustomerReviewModal: boolean;
  isAfterPurchase: boolean;
  isLandedWithOperation: boolean;
  holidayTheme: HolidayTheme;
  isSubmittingUserInteraction: boolean;
}

export const coreInitialState: CoreState = {
  isLoading: false,
  isFooterVisible: false,
  isNavigationTabsVisible: false,
  user: {
    isUserLoggedIn: false,
    userType: UserType.Customer,
  },
  isAfterPurchase: false,
  isLandedWithOperation: false,
  holidayTheme: null,
  isSubmittingUserInteraction: false,
  showCustomerReviewModal: false,
};

const reducer = createReducer(
  coreInitialState,
  on(coreActions.setLoading, (state, action) => ({
    ...state,
    isLoading: action.isLoading,
  })),
  on(coreActions.setFooterVisibility, (state, action) => ({
    ...state,
    isFooterVisible: action.isFooterVisible,
  })),
  on(coreActions.setNavigationTabsVisibility, (state, action) => ({
    ...state,
    isNavigationTabsVisible: action.isNavigationTabsVisible,
  })),
  on(coreActions.setIsSubmittingUserInteraction, (state, action) => ({
    ...state,
    isSubmittingUserInteraction: action.isSubmittingUserInteraction,
  })),
  on(coreActions.setIsAfterPurchase, (state, action) => ({
    ...state,
    isAfterPurchase: action.isAfterPurchase,
  })),
  on(coreActions.setIsLandedWithOperation, (state, action) => ({
    ...state,
    isLandedWithOperation: action.isLandedWithOperation,
  })),
  on(coreActions.setIsUserLoggedIn, (state, action) => ({
    ...state,
    user: {
      ...state.user,
      isUserLoggedIn: action.isUserLoggedIn,
    },
  })),
  on(coreActions.setUserType, (state, action) => ({
    ...state,
    user: {
      ...state.user,
      userType: action.userType,
    },
  })),
  on(coreActions.setHolidayTheme, (state, action) => ({
    ...state,
    holidayTheme: action.holidayTheme,
  })),
  on(coreActions.setShowCustomerReviewModal, (state, action) => ({
    ...state,
    showCustomerReviewModal: action.showCustomerReviewModal,
  })),
);

export function coreReducer(state: CoreState, action: Action): CoreState {
  return reducer(state, action);
}
