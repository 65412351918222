import { createAction, props } from '@ngrx/store';

import { CombinedPaymentDetails } from '../models/combined-payment-details.model';

export const paymentActions = {
  loadCombinedPaymentDetails: createAction('[Payment API] Load combined payment details'),
  setCombinedPaymentDetails: createAction(
    '[Payment] Set combined payment details',
    props<{ combinedPaymentDetails: CombinedPaymentDetails }>(),
  ),
  updatePaymentMethod: createAction(
    '[Payment API] update payment method',
    props<{ token: string; policyId: number; providerTokenId: string; shouldCloseMobileBanner?: boolean }>(),
  ),
  updatePaymentMethodError: createAction(
    '[Payment] update payment method error',
    props<{ errorStatusCode?: string; errorMessage?: string }>(),
  ),
  updatePaymentMethodSuccess: createAction('[Payment API] update payment method success', props<{ shouldCloseMobileBanner?: boolean }>()),
};
