import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CancelPolicyRequest } from '../models/cancel-policy-request.model';
import { CancelPolicyRequestOld } from '../models/cancel-policy-request-old.model';
import { CancellationConfig } from '../models/cancellation-config.model';
import { CancellationConfigOld } from '../models/cancellation-config-old.model';
import { NonRenewPolicyRequest } from '../models/non-renew-policy-request.model';

@Injectable({
  providedIn: 'root',
})
export class CancellationDataService {
  constructor(private httpClient: HttpClient) {}

  /* istanbul ignore next */
  getCancellationConfigOld(policyId: number): Observable<CancellationConfigOld> {
    return this.httpClient.get<CancellationConfigOld>('/api/policies/cancellation/config', {
      params: {
        policyId,
      },
    });
  }

  /* istanbul ignore next */
  getCancellationConfig(policyId: number): Observable<CancellationConfig> {
    return this.httpClient.get<CancellationConfig>('/api/policies/cancellation/config-v2', {
      params: {
        policyId,
      },
    });
  }

  /* istanbul ignore next */
  cancelPolicyOld(policyId: number, request: CancelPolicyRequestOld): Observable<void> {
    return this.httpClient.post<void>('/api/policies/cancel', {
      policyId,
      ...request,
    });
  }

  /* istanbul ignore next */
  cancelPolicy(policyId: number, cancelPolicyRequest: CancelPolicyRequest): Observable<void> {
    return this.httpClient.post<void>('/api/policies/cancel-v2', {
      policyId,
      cancelPolicyRequest,
    });
  }

  /* istanbul ignore next */
  nonRenewPolicy(policyId: number, request: NonRenewPolicyRequest): Observable<void> {
    return this.httpClient.post<void>('/api/policies/do-not-renew', {
      policyId,
      ...request,
    });
  }

  /* istanbul ignore next */
  undoPolicyCancellation(policyId: number): Observable<void> {
    return this.httpClient.post<void>('/api/policies/cancellation/undo', {
      policyId,
    });
  }
}
