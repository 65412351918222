import { Injectable } from '@angular/core';

import { InteractionType } from '../../core/models/interaction-type.enum';
import { TrackingService } from '../../core/services/tracking.service';
import { AuthErrorStatuses } from '../models/auth-error-status.enum';
import { Auth0ErrorStatus } from '../models/auth0-error-status.enum';

@Injectable({
  providedIn: 'root',
})
export class LoginTrackingService {
  loginFlowPlacement = 'login-flow';

  constructor(private trackingService: TrackingService) {}

  /* istanbul ignore next */
  trackNextLoginCallbackSuccess(returnUrl: string): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Api,
        placement: this.loginFlowPlacement,
        name: 'next-login-exchange-code-success',
        interactionData: {
          returnUrl,
        },
        eventName: 'next login exchange code - API login success',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackOktaLoginCallbackStart(paramsString: string): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.loginFlowPlacement,
      name: 'okta-login-callback-start',
      interactionData: {
        paramsString,
      },
    });
  }

  /* istanbul ignore next */
  trackLandingAfterNextLogin(paramsString: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Event,
      placement: this.loginFlowPlacement,
      name: 'landing-after-next-login',
      interactionData: {
        paramsString,
      },
    });
  }

  /* istanbul ignore next */
  trackNavigateToNextLogin(portalLoginURL: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Navigation,
      placement: this.loginFlowPlacement,
      name: 'navigate-to-next-login',
      interactionData: {
        portalLoginURL,
      },
    });
  }

  /* istanbul ignore next */
  trackNextLoginLogout(): void {
    this.trackingService.track({
      interactionType: InteractionType.Event,
      placement: this.loginFlowPlacement,
      name: 'next-login-logout',
    });
  }

  /* istanbul ignore next */
  trackNextLoginCallbackAuth0Error(errorId: Auth0ErrorStatus, errorDescription: string): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Error,
        placement: this.loginFlowPlacement,
        name: 'next-login-callback-auth0-error',
        interactionData: {
          error: errorId,
          errorDescription,
        },
        eventName: 'next login callback - API login auth0 error',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackNextLoginExchangeCodeError(errorReason: AuthErrorStatuses): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Error,
        placement: this.loginFlowPlacement,
        name: 'next-login-exchange-code-error',
        interactionData: {
          errorReason,
        },
        eventName: 'next login exchange code - API login exchange code error',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackAgencyNavigation(isProductRedirectFlow: boolean, hasEligibleBusiness: boolean): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Event,
        placement: this.loginFlowPlacement,
        name: 'navigate-to-agency-after-login',
        interactionData: {
          isProductRedirectFlow,
          hasEligibleBusiness,
        },
      },
      true,
    );
  }

  // TODO: Remove this method once this bug is solved: https://nextinsurance.atlassian.net/browse/NI-107510
  /* istanbul ignore next */
  trackNextLoginCodeVerifierSet(param: { valueFromCookie: string; name: string; valueFromStorage: string; value: string }): void {
    this.trackingService.track({
      interactionType: InteractionType.Debug,
      placement: this.loginFlowPlacement,
      name: 'next-login-code-verifier-set',
      interactionData: JSON.stringify(param),
    });
  }
}
