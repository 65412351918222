<div [ngClass]="{ loading: !isFormReady }">
  <ni-loader *ngIf="!isFormReady" [isLoading]="true" data-test="loader"></ni-loader>
  <div [ngClass]="{ hidden: !isFormReady }" data-test="form-content">
    <div *ngIf="shouldDisplayTitle" class="title" data-test="title-content">
      <span class="p-sm_md">{{ 'PAYMENT.PAYMENT_FORM.ENTER_BANK_ACCOUNT_DETAILS' | translate }}</span>
      <i [pTooltip]="'PAYMENT.PAYMENT_FORM.ENTER_BANK_ACCOUNT_DETAILS_TOOLTIP' | translate" [tooltipPosition]="TooltipPosition.Bottom"></i>
    </div>
    <div class="ach-payment">
      <ni-ach-payment-form
        [clientSecret]="accessToken()"
        [viewOptions]="achPaymentViewOptions"
        (formReady)="onFormReady()"
        (formCompleted)="onFormCompleted()"
        (formNotCompleted)="onFormNotCompleted()"
      >
      </ni-ach-payment-form>
    </div>
    <div class="submit-button-container">
      <ni-button-rb
        (click)="onSubmit()"
        [disabled]="!isFormCompleted"
        [isLoading]="isSubmitting || isConfirmingSetupIntent"
        data-test="submit-button"
        >{{ 'GENERAL.CONFIRM' | translate }}</ni-button-rb
      >
    </div>
  </div>
</div>
