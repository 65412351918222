import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AchFailureCategory, LOB, stripeAchNachaCodeConfig } from '@next-insurance/core';
import { DateFormats, getTodayFormat } from '@next-insurance/date';
import logger from '@next-insurance/logger';
import { StripeAchService } from '@next-insurance/ni-material';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { loadStripe } from '@stripe/stripe-js/pure';
import { concat, firstValueFrom, Observable, of } from 'rxjs';
import { filter, first, map, withLatestFrom } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { businessSelectors } from '../business/store/business.selectors';
import { FeatureFlags } from '../core/models/feature-flags.enum';
import { ReceiptResponse } from '../core/models/receipt-response';
import { DynamicDialogService } from '../core/services/dynamic-dialog.service';
import { FeatureFlagsService } from '../core/services/feature-flags.service';
import { FileDownloadService } from '../core/services/file-download.service';
import { NavigationService } from '../core/services/navigation.service';
import { Policy } from '../policies/models/policy.model';
import { policiesSelectors } from '../policies/store/policies.selectors';
import { BillingNotificationModalComponent } from '../shared/components/billing-notification-modal/billing-notification-modal.component';
import { AppState } from '../store';
import { UpdatePaymentModalComponent } from './components/update-payment-modal/update-payment-modal.component';
import { UpdatePaymentModalData } from './components/update-payment-modal/update-payment-modal-data.model';
import { PaymentProvider } from './enums/payment-provider.enum';
import { PaymentMethodDetails, PaymentMethodType } from './models/payment-method-details.model';
import { PaymentProviderDetails } from './models/payment-provider-details.model';
import { PaymentProviders } from './models/payment-providers.model';
import { PaymentDataService } from './payment.data.service';
import { paymentSelectors } from './store/payment.selectors';

type PaymentModalDataOnPaymentFailure = Pick<UpdatePaymentModalData, 'isSubmittingAction$' | 'onPaymentUpdateFinished'>;

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private readonly failedPaymentBannerExcludedUrls: RegExp[] = [/\/billing/];
  constructor(
    private paymentDataService: PaymentDataService,
    private fileDownloadService: FileDownloadService,
    private store: Store<AppState>,
    private dynamicDialogService: DynamicDialogService,
    private translateService: TranslateService,
    private navigationService: NavigationService,
    private stripeAchService: StripeAchService,
    private router: Router,
    private featureFlagsService: FeatureFlagsService,
  ) {}

  downloadReceipts(): Observable<boolean> {
    return this.paymentDataService.getReceiptLink().pipe(
      withLatestFrom(this.store.select(businessSelectors.getFullUserName)),
      map(([receiptResponse, fullUserName]: [ReceiptResponse, string]) => {
        if (!receiptResponse.isSupported) {
          // TODO delete after NI-45109
          throw new Error('error');
        }
        const currentDate = getTodayFormat(DateFormats.HyphensDate);
        this.fileDownloadService.openOrDownloadFile(receiptResponse.receiptUrl, `${fullUserName}-receipt-${currentDate}.pdf`);
        return true;
      }),
    );
  }

  downloadReceiptByLob(lob: LOB): Observable<boolean> {
    return this.paymentDataService.getReceiptLinkByLob(lob).pipe(
      withLatestFrom(this.store.select(businessSelectors.getFullUserName)),
      map(([receiptResponse, fullUserName]: [ReceiptResponse, string]) => {
        const currentDate = getTodayFormat(DateFormats.HyphensDate);
        this.fileDownloadService.openOrDownloadFile(receiptResponse.receiptUrl, `${fullUserName}-receipt-${lob}-${currentDate}.pdf`);
        return true;
      }),
    );
  }

  async getPaymentProviderDetails(): Promise<PaymentProviders> {
    const paymentProviderApi$: Observable<PaymentProviders> = this.paymentDataService.getPaymentProviders();
    const paymentProviderDetails: PaymentProviders = await firstValueFrom(paymentProviderApi$);
    if (this.isPaymentProvidersContainsStripe(paymentProviderDetails) && !window.stripe) {
      const stripePk = environment.stripePKs[this.getCarrierId()];
      window.stripe = await loadStripe(stripePk);
      this.stripeAchService.setStripeManually(window.stripe, stripePk);
    }

    return paymentProviderDetails;
  }

  getAchFailureCategory(paymentMethodDetails: PaymentMethodDetails): AchFailureCategory {
    if (
      !paymentMethodDetails ||
      paymentMethodDetails.type !== PaymentMethodType.BankAccount ||
      !paymentMethodDetails.achDetails.blockStatusReason
    ) {
      return null;
    }

    const failureCategory = stripeAchNachaCodeConfig[paymentMethodDetails.achDetails.blockStatusReason.nachaCode]?.failureCategory;

    if (!failureCategory) {
      logger.error(`No failure category for NachaCode: ${paymentMethodDetails.achDetails.blockStatusReason.nachaCode}`);
    }

    return failureCategory || AchFailureCategory.DeclinedPayment;
  }

  openUpdatePaymentMethodModal(modalData: UpdatePaymentModalData): void {
    const paymentMethodType = this.getPaymentMethodType();
    this.dynamicDialogService.open(UpdatePaymentModalComponent, {
      header: this.translateService.instant('PAYMENT.PAYMENT_FORM.EDIT_PAYMENT_METHOD'),
      narrow: false,
      data: {
        ...modalData,
        paymentMethodType,
      },
    });
  }

  openRelevantModalOnPaymentFailure(modalData: PaymentModalDataOnPaymentFailure): void {
    const paymentMethodType = this.getPaymentMethodType();
    if (paymentMethodType === PaymentMethodType.CreditCard) {
      this.openUpdatePaymentModalOnPaymentFailure(modalData);
    } else {
      this.openBillingNotificationModalOnPaymentFailure(modalData);
    }
  }

  navigateToApIntego(): void {
    const shouldNavigateToAgencyDashboard = this.featureFlagsService.isActive(FeatureFlags.ApIntigoNavigationLink);
    const path = shouldNavigateToAgencyDashboard ? environment.agencyDashboardLink : 'https://apintego.com/';
    this.navigationService.navigateTo(path, true);
  }

  private isPaymentProvidersContainsStripe(paymentProviderDetails: PaymentProviders): boolean {
    return Object.values(paymentProviderDetails.allowedMethods).some(
      (currPaymentProvider: PaymentProviderDetails) => currPaymentProvider.provider === PaymentProvider.Stripe,
    );
  }

  private openUpdatePaymentModalOnPaymentFailure(modalData: PaymentModalDataOnPaymentFailure): void {
    this.openUpdatePaymentMethodModal({
      displayPaymentError: true,
      ...modalData,
    });
  }

  private openBillingNotificationModalOnPaymentFailure(modalData: PaymentModalDataOnPaymentFailure): void {
    this.dynamicDialogService.open(BillingNotificationModalComponent, {
      header: this.translateService.instant('BILLING.BILLING_NOTIFICATION'),
      data: {
        isSubmittingAction$: modalData.isSubmittingAction$,
        onPaymentUpdateFinished: () => modalData.onPaymentUpdateFinished(),
      },
    });
  }

  private getCarrierId(): number {
    let carrierId: number;
    this.store
      .select(policiesSelectors.firstPolicy)
      .pipe(first())
      .subscribe((policy: Policy) => {
        carrierId = policy.carrierId;
      });
    return carrierId;
  }

  private getPaymentMethodType(): PaymentMethodType {
    let paymentMethodType: PaymentMethodType;
    this.store
      .select(paymentSelectors.getPaymentMethodDetails)
      .pipe(first())
      .subscribe((paymentMethodDetails: PaymentMethodDetails) => {
        paymentMethodType = paymentMethodDetails.type;
      });
    return paymentMethodType;
  }

  shouldDisplayFailedPaymentBannersInCurrURL(): Observable<boolean> {
    const shouldShowInURL = !this.isExcludedUrl(this.router.url);
    return concat(
      of(shouldShowInURL),
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => !this.isExcludedUrl(event.url)),
      ),
    );
  }

  private isExcludedUrl(url: string): boolean {
    return this.failedPaymentBannerExcludedUrls.some((excludedUrlPattern) => url.match(excludedUrlPattern));
  }
}
