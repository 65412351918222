import { inject, Injectable } from '@angular/core';
import { NIError } from '@next-insurance/errors';
import logger from '@next-insurance/logger';
import { WINDOW } from '@next-insurance/ng-core';
import { nanoid } from 'nanoid';
import { CookieService } from 'ngx-cookie-service';
import { lastValueFrom } from 'rxjs';

import { EnvConfig } from '../../../environments/env.config';
import { environment } from '../../../environments/environment';
import { AuthErrorStatuses } from '../../login/models/auth-error-status.enum';
import { LoginTrackingService } from '../../login/services/login-tracking.service';
import { LoginV2DataService } from '../../login/services/login-v2.data.service';
import { SESSION_STORAGE } from '../tokens/session-storage.token';
import { LoginCallbacksPath } from './app-initialization.service';

const AUTH0_URL = environment.nextLoginAuthenticationIssuer;
const CLIENT_ID = environment.authenticationIssuerClientId;

@Injectable({
  providedIn: 'root',
})
export class Auth0UtilsService {
  private window = inject(WINDOW);
  private sessionStorage: Storage = inject(SESSION_STORAGE);
  private loginTrackingService = inject(LoginTrackingService);
  private cookieService = inject(CookieService);
  private loginDataServiceV2 = inject(LoginV2DataService);

  createLogoutURL(): string {
    const paramsString = new URLSearchParams({
      client_id: CLIENT_ID,
      returnTo: `${this.window.location.origin}${LoginCallbacksPath.PostLogout}`,
    }).toString();
    return `${AUTH0_URL}v2/logout?${paramsString}`;
  }

  async createLoginURL(isProductRedirectFlow: boolean): Promise<string> {
    const { verifier, portalLoginURL } = await this.generateNewLoginUrl(isProductRedirectFlow);
    if (!verifier) {
      logger.error(`[Next Login] didn't succeed generating verifier`);
    }
    await this.setLoginVerifier(verifier);

    return portalLoginURL;
  }

  private async setLoginVerifier(verifier: string): Promise<void> {
    this.sessionStorage.setItem(environment.loginCodeVerifierCookieName, verifier);
    await lastValueFrom(this.loginDataServiceV2.setVerifier(verifier));
    const verifierFromCookie = this.cookieService.get(environment.loginCodeVerifierCookieName);
    const verifierFromStorage = this.sessionStorage.getItem(environment.loginCodeVerifierCookieName);

    this.loginTrackingService.trackNextLoginCodeVerifierSet({
      name: environment.loginCodeVerifierCookieName,
      value: verifier,
      valueFromStorage: verifierFromStorage,
      valueFromCookie: verifierFromCookie,
    });

    if (!verifierFromStorage && !verifierFromCookie) {
      logger.error(`[Next Login] failed to set login verifier - login verifier is null`);
    }
  }

  private getHostDomain(): string {
    return EnvConfig.isDev() ? environment.tld : `.${environment.tld}`;
  }

  getLoginVerifier(): string {
    const storageVerifier = this.sessionStorage.getItem(environment.loginCodeVerifierCookieName);
    const cookieVerifier = this.cookieService.get(environment.loginCodeVerifierCookieName);
    if (!storageVerifier && !cookieVerifier) {
      throw new NIError('next login verifier is missing', null, {
        niStatusCode: AuthErrorStatuses.CodeVerifierIsMissing,
      });
    }

    return storageVerifier || cookieVerifier;
  }

  deleteLoginVerifier(): void {
    this.sessionStorage.removeItem(environment.loginCodeVerifierCookieName);
    this.cookieService.delete(environment.loginCodeVerifierCookieName, '/', this.getHostDomain(), true, 'Lax');
  }

  private async generateNewLoginUrl(isProductRedirectFlow: boolean): Promise<{ verifier: string; portalLoginURL: string }> {
    const { verifier, challenge } = await this.generateVerifierChallengePair();
    const redirectUri = isProductRedirectFlow
      ? `${environment.url}${LoginCallbacksPath.PostProductRedirectLogin}`
      : `${environment.url}${LoginCallbacksPath.PostLogin}`;

    const params = new URLSearchParams({
      client_id: CLIENT_ID,
      scope: `openid profile email offline_access`,
      redirect_uri: redirectUri,
      audience: 'http://nextinsurance.com/be_api',
      response_type: 'code',
      code_challenge: challenge,
      code_challenge_method: 'S256',
    });
    const portalLoginURL = `${AUTH0_URL}authorize?${params.toString()}`;
    return { verifier, portalLoginURL };
  }

  private async generateVerifierChallengePair(): Promise<{ verifier: string; challenge: string }> {
    const verifier = this.base64URLEncode(nanoid(43));
    const challenge = await this.generateChallenge(verifier);
    return { verifier, challenge };
  }

  private base64URLEncode(str: string): string {
    return str.toString().replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  }

  private async generateChallenge(text: string): Promise<string> {
    const encoder = new TextEncoder();
    const { buffer } = encoder.encode(text);
    const hashBuffer = await window.crypto.subtle.digest({ name: 'SHA-256' }, buffer);
    const hashArray = new Uint8Array(hashBuffer);
    const hashBase64 = btoa(String.fromCharCode.apply(null, hashArray));
    return this.base64URLEncode(hashBase64);
  }
}
