import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FeedbackType, NiSystemFeedbackModule } from '@next-insurance/ni-material';
import { select, Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, Observable, skipWhile } from 'rxjs';
import { first, tap, withLatestFrom } from 'rxjs/operators';

import { Policy } from '../../../policies/models/policy.model';
import { policiesSelectors } from '../../../policies/store/policies.selectors';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { AppState } from '../../../store';
import { PaymentProvider } from '../../enums/payment-provider.enum';
import { PaymentMethodType } from '../../models/payment-method-details.model';
import { PaymentProviders } from '../../models/payment-providers.model';
import { PaymentService } from '../../payment.service';
import { BraintreePaymentFormComponent } from '../../payment-form/braintree-payment-form/braintree-payment-form.component';
import { StripeAchPaymentFormComponent } from '../../payment-form/stripe-ach-payment-form/stripe-ach-payment-form.component';
import { StripeCreditCardFormComponent } from '../../payment-form/stripe-payment-form/stripe-credit-card-form.component';
import { PaymentTrackingService } from '../../payment-tracking.service';
import { paymentActions } from '../../store/payment.actions';
import { paymentSelectors } from '../../store/payment.selectors';
import { PaymentMethodTypeButtonsComponent } from '../payment-method-type-buttons/payment-method-type-buttons.component';
import { UpdatePaymentModalData } from './update-payment-modal-data.model';

@Component({
  selector: 'ni-update-payment-modal',
  templateUrl: './update-payment-modal.component.html',
  styleUrls: ['./update-payment-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    LoaderComponent,
    NiSystemFeedbackModule,
    StripeCreditCardFormComponent,
    BraintreePaymentFormComponent,
    PaymentMethodTypeButtonsComponent,
    AsyncPipe,
    TranslateModule,
    StripeAchPaymentFormComponent,
  ],
})
export class UpdatePaymentModalComponent implements OnInit {
  modalData: UpdatePaymentModalData;
  paymentProviderDetails: PaymentProviders;
  paymentMethodTypeOptions: PaymentMethodType[] = [];
  selectedPaymentMethod: PaymentMethodType;
  shouldDisableButton$ = new BehaviorSubject(true);
  isPaymentSubmitting$: Observable<boolean>;
  policy$: Observable<Policy>;
  protected readonly PaymentProviderEnum = PaymentProvider;
  protected readonly FeedbackType = FeedbackType;
  protected readonly PaymentMethodType = PaymentMethodType;

  constructor(
    private paymentService: PaymentService,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private dynamicDialogConfig: DynamicDialogConfig<UpdatePaymentModalData>,
    private dialogRef: DynamicDialogRef,
    private paymentTrackingService: PaymentTrackingService,
  ) {}

  ngOnInit(): void {
    this.paymentTrackingService.trackViewUpdatePaymentModal();
    this.listenToCloseEvent();
    this.modalData = this.dynamicDialogConfig.data;
    this.policy$ = this.store.pipe(select(policiesSelectors.firstNextPolicy));
    this.isPaymentSubmitting$ = this.store.select(paymentSelectors.isSubmitting);
    this.selectedPaymentMethod = this.dynamicDialogConfig.data.paymentMethodType;
    this.getPaymentProviderDetails();
  }

  onSubmitStripeCreditCard($event: { token: string; providerTokenId: string }, policy: Policy): void {
    this.onSubmit($event.token, PaymentProvider.Stripe, PaymentMethodType.CreditCard, policy.policyId, $event.providerTokenId);
  }

  onSubmitBraintreeCreditCard(nonce: string, policy: Policy): void {
    this.onSubmit(nonce, PaymentProvider.Braintree, PaymentMethodType.CreditCard, policy.policyId);
  }

  onSubmitStripeACH($event: { token: string; providerTokenId: string }, policy: Policy): void {
    this.onSubmit($event.token, PaymentProvider.Stripe, PaymentMethodType.BankAccount, policy.policyId, $event.providerTokenId);
  }

  onMethodTypeSelected(paymentMethodType: PaymentMethodType): void {
    this.paymentTrackingService.trackSelectPaymentMethodType(this.selectedPaymentMethod, paymentMethodType);
    this.selectedPaymentMethod = paymentMethodType;
  }

  onErrorOccurredDuringUpdatePayment(errorMessage: string): void {
    this.store.dispatch(paymentActions.updatePaymentMethodError({ errorMessage }));
    this.getPaymentProviderDetails();
  }

  private getPaymentProviderDetails(): void {
    this.paymentService.getPaymentProviderDetails().then((res: PaymentProviders) => {
      this.paymentProviderDetails = res;
      this.paymentMethodTypeOptions = this.sortPaymentMethodsBySelectedPayment();
      this.cdr.markForCheck();
    });
  }

  private onSubmit(
    token: string,
    paymentProvider: PaymentProvider,
    paymentMethodType: PaymentMethodType,
    policyId: number,
    providerTokenId?: string,
  ): void {
    this.paymentTrackingService.trackSubmitUpdatePaymentMethod(paymentProvider, paymentMethodType);

    this.store.dispatch(
      paymentActions.updatePaymentMethod({
        token,
        policyId,
        providerTokenId,
      }),
    );

    this.listenToPaymentCardUpdateFinished();
  }

  private listenToPaymentCardUpdateFinished(): void {
    this.store
      .select(paymentSelectors.isSubmitting)
      .pipe(
        skipWhile((submitting) => !submitting),
        first((submitting) => !submitting),
        tap(() => {
          this.shouldDisableButton$.next(false);
        }),
        withLatestFrom(this.store.select(paymentSelectors.getIsUpdatePaymentMethodFailed)),
      )
      .subscribe(([submit, failed]: [boolean, boolean]) => {
        if (!submit) {
          if (failed) {
            this.getPaymentProviderDetails();
          } else {
            this.onPaymentUpdateFinished();
          }
        }
      });
  }

  private onPaymentUpdateFinished(): void {
    this.dialogRef.close(true);
    this.modalData.onPaymentUpdateFinished();
  }

  private listenToCloseEvent(): void {
    this.dialogRef.onClose.pipe(first()).subscribe((isActionTaken: boolean) => {
      if (!isActionTaken) {
        this.paymentTrackingService.trackCloseUpdatePaymentModal();
      }
    });
  }

  private sortPaymentMethodsBySelectedPayment(): PaymentMethodType[] {
    return (
      this.paymentProviderDetails &&
      (Object.keys((this.paymentProviderDetails as PaymentProviders).allowedMethods).sort((paymentMethodTypeA, paymentMethodTypeB) => {
        if (paymentMethodTypeA === this.selectedPaymentMethod) return -1;
        if (paymentMethodTypeB === this.selectedPaymentMethod) return 1;
        return paymentMethodTypeA.localeCompare(paymentMethodTypeB);
      }) as PaymentMethodType[])
    );
  }
}
