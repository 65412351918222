export enum StripeErrorCodes {
  CardDeclined = 'card_declined',
  IncompleteNumber = 'incomplete_number',
  IncorrectNumber = 'incorrect_number',
  IncorrectCvc = 'incorrect_cvc',
  IncompleteExpiry = 'incomplete_expiry',
  ExpiredCard = 'expired_card',
  IncompleteCvc = 'incomplete_cvc',
  InvalidCvc = 'invalid_cvc',
}
