import { Injectable } from '@angular/core';
import { LOB } from '@next-insurance/core';
import { DateFormats, transformDate } from '@next-insurance/date';

import { InteractionType } from '../../core/models/interaction-type.enum';
import { TrackingService } from '../../core/services/tracking.service';
import { CancellationDateType } from '../../coverage-page/models/cancellation-date-type.enum';
import { CancellationReason } from '../models/cancellation-reason.enum';

@Injectable({
  providedIn: 'root',
})
export class CancellationTrackingService {
  constructor(private trackingService: TrackingService) {}

  /* istanbul ignore next */
  trackCancelPolicyModalOpened(lob: LOB, isRenewalPolicy: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'cancel-policy-modal',
      name: 'cancel-policy-modal-opened',
      interactionData: {
        lob,
        isRenewalPolicy,
      },
    });
  }

  /* istanbul ignore next */
  trackSelectCancellationReasonFormSubmit(lob: LOB, isRenewalPolicy: boolean, cancellationReason: CancellationReason): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'cancel-policy-modal-select-reason-form',
      name: 'submit-button',
      interactionData: {
        lob,
        isRenewalPolicy,
        cancellationReason,
      },
    });
  }

  /* istanbul ignore next */
  trackCancelCoverage(lob: LOB, isRenewalPolicy: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'cancel-policy-modal',
      name: 'cancel-coverage',
      interactionData: {
        lob,
        isRenewalPolicy,
      },
    });
  }

  /* istanbul ignore next */
  trackKeepCoverage(lob: LOB, isRenewalPolicy: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'cancel-policy-modal',
      name: 'keep-coverage',
      interactionData: {
        lob,
        isRenewalPolicy,
      },
    });
  }

  /* istanbul ignore next */
  trackCancelPolicyResult(isSuccess: boolean, isRenewalPolicy: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: 'cancel-policy-modal',
      name: 'cancel-policy-result',
      interactionData: {
        isSuccess,
        isRenewalPolicy,
      },
    });
  }

  /* istanbul ignore next */
  trackNonRenewCoverage(lob: LOB): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'cancel-policy-modal',
      name: 'non-renew-coverage',
      interactionData: {
        lob,
      },
      eventName: 'cancel policy modal - CLICK non renew coverage',
    });
  }

  /* istanbul ignore next */
  trackNonRenewCoverageResult(isSuccess: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: 'cancel-policy-modal',
      name: 'non-renew-coverage-result',
      interactionData: {
        isSuccess,
      },
      eventName: 'cancel policy modal - API non renew result',
    });
  }

  /* istanbul ignore next */
  trackUndoCancellationModalOpened(lob: LOB): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'undo-cancellation-modal',
      name: 'modal-opened',
      interactionData: {
        lob,
      },
      eventName: 'home page - undo cancellation modal - VIEW',
    });
  }

  /* istanbul ignore next */
  trackUndoCancellationModalClosed(lob: LOB): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'undo-cancellation-modal',
      name: 'modal-closed',
      interactionData: {
        lob,
      },
      eventName: 'home page - undo cancellation modal - CLICK close',
    });
  }

  /* istanbul ignore next */
  trackUndoCancellationModalSubmit(lob: LOB): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'undo-cancellation-modal',
      name: 'undo-cancellation-button',
      interactionData: {
        lob,
      },
      eventName: 'home page - undo cancellation modal - CLICK undo',
    });
  }

  /* istanbul ignore next */
  trackUndoCancellationSuccess(lob: LOB): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: 'undo-cancellation-modal',
      name: 'undo-cancellation-success',
      interactionData: {
        lob,
      },
      eventName: 'home page - undo cancellation modal - API undo cancellation succeeded',
    });
  }

  /* istanbul ignore next */
  trackUndoCancellationFailure(lob: LOB, errorReason?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: 'undo-cancellation-modal',
      name: 'undo-cancellation-failure',
      interactionData: {
        lob,
        errorReason,
      },
      eventName: 'home page - undo cancellation modal - API undo cancellation failed',
    });
  }

  /* istanbul ignore next */
  trackSelectedCancellationType(lob: LOB, cancellationDateType: CancellationDateType): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'cancel-policy-modal',
      name: 'cancellation-date-type-selected',
      interactionData: {
        lob,
        type: cancellationDateType,
      },
      eventName: 'cancel policy modal - CLICK cancellation date type option',
    });
  }

  /* istanbul ignore next */
  trackSelectedDate(lob: LOB, date: Date): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'cancel-policy-modal',
      name: 'date-selected',
      interactionData: {
        lob,
        date: transformDate(date, DateFormats.HyphensDate),
      },
      eventName: 'cancel policy modal - CLICK date',
    });
  }
}
