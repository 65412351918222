import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { BusinessInformationAssortmentEligibilityResponse } from '../../core/models/business-information-assortment-eligibility-response.model';
import { BusinessInformationAssortmentFlowType } from '../../core/models/business-information-assortment-flow-type.enum';
import { CobSegment } from '../../core/models/cob-segment.enum';
import { BusinessInformationAssortmentDataService } from '../../core/services/business-information-assortment.data.service';
import { SurvicateService } from '../../core/services/survicate.service';
import { VerificationV2DataService } from '../../core/services/verification-v2.data.service';
import { SESSION_STORAGE } from '../../core/tokens/session-storage.token';
import { BusinessMoratoriums } from '../../home/models/business-moratoriums.model';
import { ToastType } from '../../shared/components/toast/models/toast-type.enum';
import { toastActions } from '../../shared/components/toast/store/toast.actions';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../store';
import { navigationActions } from '../../store/navigation.actions';
import { BusinessDataService } from '../business.data.service';
import { PhoneVerificationModalComponent } from '../components/phone-verification-modal/phone-verification-modal.component';
import { AuthorizedUser } from '../models/authorized-user.model';
import { Business } from '../models/business.model';
import { BusinessGeneralInfo } from '../models/business-general-info.model';
import { BusinessPeopleInfo } from '../models/business-people-info.model';
import { BusinessPropertyInfo } from '../models/business-property-info.model';
import { CertificateActivity } from '../models/certificate-activity.model';
import { AuthorizedUserTrackingService } from '../services/authorized-user-tracking.service';
import { businessActions } from './business.actions';
import { businessSelectors } from './business.selectors';

@Injectable()
export class BusinessEffects {
  loadBusiness$ = createEffect(() =>
    this.actions$.pipe(
      ofType(businessActions.loadBusiness),
      withLatestFrom(this.store.select(businessSelectors.getBusinessId)),
      switchMap(([action, businessId]: [{ successActions?: Action[] }, string]) => {
        return this.businessService.getBusiness().pipe(
          tap((businessDetails: Business) => {
            this.survicateService.updateTraitsByBusiness(businessDetails);
            if (window.NI_NAMESPACE) {
              window.NI_NAMESPACE.email = businessDetails.emailAddress;
              window.NI_NAMESPACE.businessId = businessId;
            }
          }),
          mergeMap((businessDetails) => {
            return [businessActions.setBusiness({ businessDetails }), ...(action.successActions || [])];
          }),
          catchErrorAndLog(() => of(navigationActions.toError())),
        );
      }),
    ),
  );

  loadMoratoriums$ = createEffect(() =>
    this.actions$.pipe(
      ofType(businessActions.loadMoratoriums),
      switchMap(() =>
        this.businessService.getMoratoriums().pipe(
          map((moratoriums: BusinessMoratoriums) => {
            return businessActions.setMoratoriums({ moratoriums });
          }),
          catchErrorAndLog(() => of(navigationActions.toError())),
        ),
      ),
    ),
  );

  loadCertificateActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(businessActions.loadCertificatesActivities),
      switchMap((action: { activitiesNumber: number }) =>
        this.businessService.getCertificatesActivities(action.activitiesNumber).pipe(
          mergeMap((certificatesActivities: CertificateActivity[]) => [
            businessActions.setCertificatesActivities({ certificatesActivities }),
          ]),
          catchErrorAndLog(() => {
            return of(businessActions.setCertificatesActivities({ certificatesActivities: [] }));
          }),
        ),
      ),
    ),
  );

  createCertificateActivity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(businessActions.createCertificateActivity),
        switchMap(({ liveCertificateId, certificateActivityType }) => {
          return this.businessService.createCertificateActivity(liveCertificateId, certificateActivityType);
        }),
      ),
    { dispatch: false },
  );

  addAuthorizedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(businessActions.addAuthorizedUser),
      switchMap((action: { authorizedUser: AuthorizedUser }) =>
        this.businessService.addAuthorizedUser(action.authorizedUser).pipe(
          mergeMap(() => {
            this.authorizedUserTrackingService.trackAddAuthorizedUserResult(true, action.authorizedUser);
            return [
              navigationActions.toBusinessPage(),
              toastActions.showToast({ toastType: ToastType.Success, message: 'ADD_AUTHORIZED_USERS.SUCCESS_TOAST' }),
            ];
          }),
          catchErrorAndLog(() => {
            this.authorizedUserTrackingService.trackAddAuthorizedUserResult(false);
            return [toastActions.showGeneralErrorToast()];
          }),
        ),
      ),
    ),
  );

  loadGeneralInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(businessActions.loadGeneralInfo),
      switchMap(() => {
        return this.businessService.getGeneralInfo().pipe(
          map((generalInfo: BusinessGeneralInfo) => {
            return businessActions.setGeneralInfo({ generalInfo });
          }),
          catchErrorAndLog(() => [toastActions.showLoadingErrorToast(), navigationActions.toHomePage({})]),
        );
      }),
    ),
  );

  loadPeopleInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(businessActions.loadPeopleInfo),
      switchMap(() =>
        this.businessService.getPeopleInfo().pipe(
          map((peopleInfo: BusinessPeopleInfo) => {
            return businessActions.setPeopleInfo({ peopleInfo });
          }),
          catchErrorAndLog(() => of(toastActions.showLoadingErrorToast())),
        ),
      ),
    ),
  );

  loadPropertyInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(businessActions.loadPropertyInfo),
      switchMap(() =>
        this.businessService.getPropertyInfo().pipe(
          map((propertyInfo: BusinessPropertyInfo) => {
            return businessActions.setPropertyInfo({ propertyInfo });
          }),
          catchErrorAndLog(() => of(toastActions.showLoadingErrorToast())),
        ),
      ),
    ),
  );

  loadIsEmailVerified$ = createEffect(() =>
    this.actions$.pipe(
      ofType(businessActions.loadIsEmailVerified),
      concatLatestFrom(() => [this.store.select(businessSelectors.getEmailAddress)]),
      switchMap(([, emailAddress]: [Action, string]) => {
        return this.verificationV2DataService.isVerified(emailAddress).pipe(
          map((isEmailVerified: boolean) => {
            return businessActions.setIsEmailVerified({ isEmailVerified });
          }),
          catchErrorAndLog(() => of(toastActions.showLoadingErrorToast())),
        );
      }),
    ),
  );

  loadIsPhoneVerified$ = createEffect(() =>
    this.actions$.pipe(
      ofType(businessActions.loadIsPhoneVerified),
      concatLatestFrom(() => [this.store.select(businessSelectors.getPhoneNumber)]),
      switchMap(([, phoneNumber]: [Action, string]) => {
        return this.verificationV2DataService.isVerified(phoneNumber).pipe(
          map((isPhoneVerified: boolean) => {
            if (!isPhoneVerified) this.sessionStorage.setItem(PhoneVerificationModalComponent.verificationCodeSentStorageKey, '');
            return businessActions.setIsPhoneVerified({ isPhoneVerified });
          }),
          catchErrorAndLog(() => of(toastActions.showLoadingErrorToast())),
        );
      }),
    ),
  );

  loadSegmentByCob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(businessActions.loadSegmentByCob),
      switchMap((action: { cobId: number }) =>
        this.businessService.getSegmentByCob(action.cobId).pipe(
          map((cobSegment: CobSegment) => {
            return businessActions.setCobSegment({ cobSegment });
          }),
          catchErrorAndLog(() => of(businessActions.setLoadingCobSegment({ isLoadingCobSegment: false }))),
        ),
      ),
    ),
  );

  loadIsEligibleForBusinessInformationAssortment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(businessActions.loadIsEligibleForBusinessInformationAssortment),
      switchMap((action: { businessInformationAssortmentFlowType: BusinessInformationAssortmentFlowType }) => {
        return this.businessInformationAssortmentDataService
          .isEligibleForBusinessInformationAssortment(action.businessInformationAssortmentFlowType)
          .pipe(
            switchMap((res: BusinessInformationAssortmentEligibilityResponse) => {
              return [
                businessActions.setIsEligibleForBusinessInformationAssortment({
                  isEligible: res.eligible,
                  businessInformationAssortmentFlowType: action.businessInformationAssortmentFlowType,
                }),
              ];
            }),
            catchErrorAndLog(() =>
              of(
                businessActions.setIsEligibleForBusinessInformationAssortment({
                  businessInformationAssortmentFlowType: action.businessInformationAssortmentFlowType,
                  isEligible: false,
                }),
              ),
            ),
          );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private businessService: BusinessDataService,
    private survicateService: SurvicateService,
    private authorizedUserTrackingService: AuthorizedUserTrackingService,
    private verificationV2DataService: VerificationV2DataService,
    private businessInformationAssortmentDataService: BusinessInformationAssortmentDataService,
    @Inject(SESSION_STORAGE) private sessionStorage: Storage,
  ) {}
}
