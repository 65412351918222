import { Action, createReducer, on } from '@ngrx/store';

import { ClaimDetails } from '../models/claim-details.model';
import { claimsActions } from './claims.actions';

export interface ClaimsState {
  claims: ClaimDetails[];
}

export const claimsInitialState: ClaimsState = {
  claims: [],
};

const reducer = createReducer(
  claimsInitialState,
  on(claimsActions.setClaims, (state, action) => ({
    ...state,
    claims: action.claims,
  })),
  on(claimsActions.setIsAnyFileUploadedByUser, (state, action) => ({
    ...state,
    claims: state.claims.map((claim) =>
      claim.claimId === action.claimId
        ? {
            ...claim,
            isAnyFileUploadedByUser: true,
          }
        : claim,
    ),
  })),
);

export function claimsReducer(state: ClaimsState, action: Action): ClaimsState {
  return reducer(state, action);
}
