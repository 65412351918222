import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ButtonType } from '@next-insurance/ni-material';
import { Store } from '@ngrx/store';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, of } from 'rxjs';
import { delay, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';

import { verificationConfig } from '../../../core/config/verification.config';
import { VerificationScope } from '../../../core/models/verification-scope.model';
import { ZendeskIssueTag } from '../../../core/models/zendesk-issue-tag.enum';
import { CommonConfigDataService } from '../../../core/services/common-config.data.service';
import { MobileAppService } from '../../../core/services/mobile-app.service';
import { NiValidatorsService } from '../../../core/services/ni-validators.service';
import { VerificationV2DataService } from '../../../core/services/verification-v2.data.service';
import { ZendeskService } from '../../../core/services/zendesk.service';
import { SESSION_STORAGE } from '../../../core/tokens/session-storage.token';
import { ToastType } from '../../../shared/components/toast/models/toast-type.enum';
import { toastActions } from '../../../shared/components/toast/store/toast.actions';
import { catchErrorAndLog } from '../../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../../store';
import { navigationActions } from '../../../store/navigation.actions';
import { PhoneVerificationModalTrackingService } from '../../services/phone-verification-modal-tracking.service';
import { businessActions } from '../../store/business.actions';
import { businessSelectors } from '../../store/business.selectors';

enum Status {
  Initial = 'INITIAL',
  Completed = 'COMPLETED',
}

@Component({
  selector: 'ni-phone-verification-modal',
  templateUrl: './phone-verification-modal.component.html',
  styleUrls: ['./phone-verification-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneVerificationModalComponent implements OnInit {
  static readonly verificationCodeSentStorageKey = 'verificationCodeSentToPhone';
  readonly DELAY_BEFORE_CHECK_IF_VERIFIED = 2000;
  readonly DELAY_BETWEEN_STEPS = 2000;
  readonly verificationCodeErrors = verificationConfig.codeErrors;
  ButtonType = ButtonType;
  currentStatus = Status.Initial;
  headers = {
    [Status.Initial]: 'VERIFICATION.PHONE.INITIAL.HEADER_BIZ_PAGE',
    [Status.Completed]: 'VERIFICATION.COMPLETED.HEADER',
  };

  verificationCodeLength: number;
  currIdentityInput$: Observable<string>;
  verificationForm: UntypedFormGroup;
  showAnimation: boolean;
  isSubmitting: boolean;
  isVerificationCompleted: boolean;
  isMobileAppView = this.mobileAppService.isMobileAppWebview();

  constructor(
    private store: Store<AppState>,
    private commonConfigDataService: CommonConfigDataService,
    @Inject(SESSION_STORAGE) private sessionStorage: Storage,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private verificationV2DataService: VerificationV2DataService,
    private verificationTrackingService: PhoneVerificationModalTrackingService,
    private zendeskService: ZendeskService,
    private mobileAppService: MobileAppService,
    private dialogRef: DynamicDialogRef,
  ) {}

  /* istanbul ignore next */
  get verificationCodeControl(): AbstractControl {
    return this.verificationForm.controls.code;
  }

  /* istanbul ignore next */
  get verificationCode(): string {
    return this.verificationCodeControl.value;
  }

  /* istanbul ignore next */
  set verificationCode(code: string) {
    this.verificationForm.patchValue({ code });
  }

  openZendeskChat(): void {
    this.zendeskService.openZendeskChat(undefined, [ZendeskIssueTag.ChatPhoneVerificationIssue]);
  }

  ngOnInit(): void {
    this.currIdentityInput$ = this.store.select(businessSelectors.getPhoneNumber).pipe(
      map((identityInput) => {
        let trimmedIdentityInput = identityInput?.trim();
        if (NiValidatorsService.isPhoneValid(identityInput)) {
          trimmedIdentityInput = trimmedIdentityInput.replace(/[\s()-]/g, '');
        }
        return trimmedIdentityInput;
      }),
    );

    this.verificationCodeLength = this.commonConfigDataService.getVerificationCodeLength();
    this.initVerificationForm();
    this.verificationTrackingService.trackVerificationLanding();

    if (!this.sessionStorage.getItem(PhoneVerificationModalComponent.verificationCodeSentStorageKey)) {
      this.sendVerificationCodeToUser(false);
      this.sessionStorage.setItem(PhoneVerificationModalComponent.verificationCodeSentStorageKey, '1');
    }
  }

  onCloseModal(): void {
    this.sessionStorage.setItem(PhoneVerificationModalComponent.verificationCodeSentStorageKey, '');
    this.dialogRef.close();
    this.verificationTrackingService.trackVerificationModalClosed();
  }

  onInputChange(): void {
    this.verificationCode = this.verificationCode.replace(/\s/g, '');

    this.changeAnimationVisibility(this.verificationCode.length > 0);

    if (this.verificationCode.length === this.verificationCodeLength) {
      if (this.isVerificationCodeValid()) {
        this.onSubmit();
      } else {
        this.verificationCodeControl.setErrors({ default: true });
      }
    }
  }

  onPaste(event: ClipboardEvent): void {
    const value = event.clipboardData.getData('text');
    this.verificationCode = value.trim().substr(0, this.verificationCodeLength);
  }

  resendCode(): void {
    this.verificationTrackingService.trackVerificationResendCodeClicked();
    this.sendVerificationCodeToUser(true);
  }

  protected sendVerificationCodeToUser(isResend: boolean): void {
    this.currIdentityInput$
      .pipe(
        filter((identityInput: string) => !!identityInput),
        take(1),
        switchMap((identityInput: string) => {
          return this.verificationV2DataService.sendOtp(identityInput, VerificationScope.BusinessPage);
        }),
      )
      .subscribe({
        next: () => {
          this.verificationTrackingService.trackVerificationSendCodeResult(true, isResend);
          if (isResend) {
            this.store.dispatch(
              toastActions.showToast({
                toastType: ToastType.Success,
                message: 'VERIFICATION.RESEND_CODE_SUCCESS.PHONE',
              }),
            );
          }
        },
        error: (error: HttpErrorResponse) => {
          this.verificationTrackingService.trackVerificationSendCodeResult(false, isResend, error.error?.niStatusCode);
          this.handleSendOtpError(error);
        },
      });
  }

  private initVerificationForm(): void {
    this.verificationForm = this.fb.group({
      code: ['', []],
    });
  }

  private isVerificationCodeValid(): boolean {
    return !!new RegExp(`^\\d{${this.verificationCodeLength}}$`).test(this.verificationCode);
  }

  private changeAnimationVisibility(isVisible: boolean): void {
    this.showAnimation = isVisible;
    this.changeDetectorRef.markForCheck();
  }

  private setIsSubmitting(isSubmitting: boolean): void {
    this.isSubmitting = isSubmitting;
    this.changeDetectorRef.markForCheck();
  }

  private onSubmit(): void {
    this.setIsSubmitting(true);
    this.currIdentityInput$
      .pipe(
        filter((identityInput: string) => !!identityInput),
        take(1),
        switchMap((identityInput: string) => {
          return this.verificationV2DataService.validateOtp(identityInput, this.verificationCode);
        }),
      )
      .pipe(
        tap(() => {
          this.verificationTrackingService.trackVerificationValidateCodeResult(true);
          this.store.dispatch(businessActions.setIsPhoneVerified({ isPhoneVerified: true }));
          this.changeAnimationVisibility(false);
          this.showVerificationSucceeded();
        }),
        catchErrorAndLog((error: HttpErrorResponse) => {
          this.verificationTrackingService.trackVerificationValidateCodeResult(false, error.error?.niStatusCode);
          this.handleValidateOtpError(error);
          return of(false);
        }),
        finalize(() => {
          this.setIsSubmitting(false);
        }),
        switchMap(() => {
          return of(null).pipe(
            delay(this.DELAY_BEFORE_CHECK_IF_VERIFIED),
            tap(() => this.store.dispatch(businessActions.loadIsPhoneVerified())),
          );
        }),
      )
      .subscribe();
  }

  private showVerificationSucceeded(): void {
    this.isVerificationCompleted = true;
    this.currentStatus = Status.Completed;
    this.changeDetectorRef.markForCheck();
    this.waitAndCloseModal();
  }

  private waitAndCloseModal(): void {
    setTimeout(() => {
      this.onCloseModal();
    }, this.DELAY_BETWEEN_STEPS);
  }

  private handleSendOtpError(error: HttpErrorResponse): void {
    if (error.error?.niStatusCode in this.verificationCodeErrors) {
      this.verificationCodeControl.setErrors({ [error.error?.niStatusCode]: true });
      this.changeDetectorRef.markForCheck();
    } else {
      this.store.dispatch(navigationActions.toError());
    }
  }

  private handleValidateOtpError(error: HttpErrorResponse): void {
    if (error.error?.niStatusCode in this.verificationCodeErrors) {
      this.verificationCodeControl.setErrors({ [error.error?.niStatusCode]: true });
    } else {
      this.verificationCodeControl.setErrors({ default: true });
    }
    this.changeDetectorRef.markForCheck();
  }
}
