export interface AbTestConfig {
  name: string;
  testVariant: string;
  controlVariant: string;
}

export const coiCreationAbTestConfig: AbTestConfig = {
  name: 'coi-creation',
  controlVariant: 'coi-creation-control',
  testVariant: 'coi-creation-test',
};
