export class EmailValidatorService {
  private static readonly oldEmailRegex: RegExp =
    // eslint-disable-next-line unicorn/better-regex
    /^(([^<>()\[\]\\.,;:\s@"]{1,64}(\.[^<>()\[\]\\.,;:\s@"]{1,64})*)|(".{1,64}"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]{1,64}\.)+[a-zA-Z]{2,}))$/;

  private static readonly emailRegex: RegExp =
    // eslint-disable-next-line unicorn/better-regex
    /^(([^<>()\?\[\]\\.,;:\s@"]{1,64}(\.[^<>()\?\[\]\\.,;:\s@"]{1,64})*)|(".{1,64}"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]{1,64}\.)+[a-zA-Z]{2,}))$/;

  /* istanbul ignore next */
  static isValidOld(value: string): boolean {
    // TODO remove the old email regex from authentication flows once backend finished to update the invalid emails
    if (!value) {
      return true;
    }
    const trimmedEmail = value.trim();
    return trimmedEmail.length <= 255 && EmailValidatorService.oldEmailRegex.test(trimmedEmail);
  }

  static isValid(value: string): boolean {
    if (!value) {
      return true;
    }
    const trimmedEmail = value.trim();
    return trimmedEmail.length <= 255 && EmailValidatorService.emailRegex.test(trimmedEmail);
  }
}
