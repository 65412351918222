import { LOB } from '@next-insurance/core';
import { createAction, props } from '@ngrx/store';

import { Policy } from '../../policies/models/policy.model';
import { CancelPolicyRequestOld } from '../models/cancel-policy-request-old.model';
import { CancellationErrorReason } from '../models/cancellation-error-reason.enum';
import { NonRenewPolicyRequest } from '../models/non-renew-policy-request.model';

const cancelPolicyOld = createAction(
  '[Cancellation API] Cancel a policy (old)',
  props<{ policyId: number; lob: LOB; isRenewalPolicy: boolean; request: CancelPolicyRequestOld }>(),
);

const cancelPolicySucceed = createAction('[Cancellation] Cancel a policy succeed', props<{ lob: LOB; isRenewalPolicy: boolean }>());

const cancelPolicyFailed = createAction(
  '[Cancellation] Cancel a policy failed',
  props<{ errorReason: CancellationErrorReason; isRenewalPolicy: boolean }>(),
);

const nonRenewPolicy = createAction(
  '[Cancellation API] Non renew a policy',
  props<{ policyId: number; lob: LOB; request: NonRenewPolicyRequest }>(),
);

const nonRenewPolicySucceed = createAction('[Cancellation] Non renew a policy succeed', props<{ lob: LOB }>());

const nonRenewPolicyFailed = createAction('[Cancellation] Non renew a policy failed', props<{ errorReason: string }>());

const undoPolicyCancellation = createAction('[Cancellation API] Undo policy cancellation', props<{ policy: Policy }>());

export const cancellationActions = {
  cancelPolicyOld,
  cancelPolicySucceed,
  cancelPolicyFailed,
  nonRenewPolicy,
  nonRenewPolicySucceed,
  nonRenewPolicyFailed,
  undoPolicyCancellation,
};
