/* eslint-disable no-console */
import { EnvironmentInjector, inject, Injectable } from '@angular/core';
import { SentryErrorHandler } from '@sentry/angular';

import { FullstoryEvent } from '../../models/fullstory-event.enum';
import { FullStoryService } from '../../services/fullstory.service';
import { findOriginalError } from './helpers/get-original-error';
import { isAnyChunkLoadError } from './helpers/is-chunk-load-error';
import { errorHandlerConfig } from './ng-error-handler.config';

@Injectable()
export class NGErrorHandler extends SentryErrorHandler {
  private fullstoryService: FullStoryService;

  constructor(private injector: EnvironmentInjector) {
    super({ showDialog: false });
  }

  handleError(error: unknown): void {
    this.injector.runInContext(() => {
      if (!this.fullstoryService) {
        this.fullstoryService = inject(FullStoryService);
      }

      this.fullstoryService.fireEvent(FullstoryEvent.Error, { errorMessage: (error as any).message });

      const orgError = findOriginalError(error) || error;
      if (isAnyChunkLoadError(orgError)) {
        this.handleChunkLoadError((orgError as any).rejection || orgError);

        return;
      }

      super.handleError(error);
    });
  }

  private handleChunkLoadError(error: Error): void {
    console.error(`ChunkLoadError: Refreshing page in ${errorHandlerConfig.errors.ChunkLoadError.refreshDelay / 1000} sec\n`, error);

    setTimeout(() => {
      window.location.reload();
    }, errorHandlerConfig.errors.ChunkLoadError.refreshDelay);
  }
}
