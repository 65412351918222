import { niErrorIntegration } from '@next-insurance/logger';
import { BrowserOptions, browserTracingIntegration, ErrorEvent, EventHint } from '@sentry/angular';

import { EnvConfig } from '../../../environments/env.config';
import { environment } from '../../../environments/environment';
import { errorsFilter } from '../handlers/error-handler/errors-filter/errors-filter';

export const sentryOptions: BrowserOptions = {
  dsn: environment.sentryClientDsn,
  allowUrls: [
    // Portal PROD
    'https://portal.nextinsurance.com',
    // Portal STG
    'https://portal.uat.nextinsurance.com',
    // LMS PROD
    'https://track.nextinsurance.com',
    // LMS STG
    'https://track.uat.nextinsurance.com',
  ],
  integrations: [browserTracingIntegration(), niErrorIntegration()],
  beforeSend(event: ErrorEvent, hint: EventHint): PromiseLike<ErrorEvent | null> | ErrorEvent | null {
    const shouldFilterErrorEvent = errorsFilter(event, hint);
    return shouldFilterErrorEvent ? null : event;
  },
  tracesSampleRate: 0.01,
  environment: EnvConfig.getEnv(),
  release: EnvConfig.getAppVersion(),
};
