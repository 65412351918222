import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import logger from '@next-insurance/logger';
import { NiButtonRbModule } from '@next-insurance/ni-material';
import { NiTrimInputDirective } from '@next-insurance/ni-material/ni-trim-input-directive';
import { TranslateModule } from '@ngx-translate/core';
import { SetupIntentResult, Stripe } from '@stripe/stripe-js';
import { StripeCardCvcElement, StripeCardExpiryElement, StripeCardNumberElement } from '@stripe/stripe-js/types/stripe-js/elements';
import { StripeElementChangeEvent } from '@stripe/stripe-js/types/stripe-js/elements/base';
import { StripeCardNumberElementChangeEvent } from '@stripe/stripe-js/types/stripe-js/elements/card-number';
import { StripeElements } from '@stripe/stripe-js/types/stripe-js/elements-group';

import { STRIPE } from '../../../core/tokens/stripe.token';
import { FormErrorMessageDirective } from '../../../shared/directives/form-validation/form-error-message.directive';
import { FormSubmitDirective } from '../../../shared/directives/form-validation/form-submit.directive';
import { StripeErrorCodes } from '../../enums/stripe-error-codes.enum';
import { CreditCardIconsComponent } from '../credit-card-icons/credit-card-icons.component';

enum CreditCardElement {
  CardNumber = 'cardNumber',
  CardExpiry = 'cardExpiry',
  CardCvc = 'cardCvc',
}

type CreditCardErrors = Partial<Record<CreditCardElement, string>>;

@Component({
  selector: 'ni-stripe-credit-card-form',
  templateUrl: './stripe-credit-card-form.component.html',
  styleUrls: ['./stripe-credit-card-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormSubmitDirective,
    NgFor,
    NgClass,
    FormErrorMessageDirective,
    NgIf,
    NiButtonRbModule,
    TranslateModule,
    CreditCardIconsComponent,
    NiTrimInputDirective,
  ],
})
export class StripeCreditCardFormComponent implements OnInit, AfterViewInit, OnChanges {
  errors: CreditCardErrors = {};
  form: UntypedFormGroup;
  selectedCardType: string;
  @Output() submitted = new EventEmitter<{ token: string; providerTokenId: string }>();
  @Output() error = new EventEmitter<string>();
  @Input() submitText: string;
  @Input() carrier: number;
  @Input() isSubmitting: boolean;
  @Input() accessToken: string;
  @Input() providerRequestTokenId: string;
  isConfirmingSetupIntent = false;
  private cardNumber: StripeCardNumberElement;
  private cardExpiration: StripeCardExpiryElement;
  private cardSecurityCode: StripeCardCvcElement;

  constructor(
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(STRIPE) private stripe: Stripe,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.providerToken && !changes.providerToken.isFirstChange()) {
      this.initStripeElements();
    }
  }

  ngAfterViewInit(): void {
    this.initStripeElements();
  }

  async submit(): Promise<void> {
    try {
      this.setIsConfirmingSetupIntent(true);
      const setupIntentResult = await this.stripe.confirmCardSetup(this.accessToken, { payment_method: { card: this.cardNumber } });
      this.setIsConfirmingSetupIntent(false);
      if (this.validateSetupIntent(setupIntentResult)) {
        this.submitted.emit({
          token: setupIntentResult.setupIntent.payment_method as string,
          providerTokenId: this.providerRequestTokenId,
        });
      } else {
        this.error.emit(setupIntentResult.error.message);
      }
    } catch (error) {
      logger.error('StripeCreditCardFormComponent | submit: Could not confirm setup intent with Stripe API', error);
    }
  }

  private initStripeElements(): void {
    this.createStripeElements(this.stripe.elements({ clientSecret: this.accessToken }));
    this.attachEventHandlers();
    this.cardNumber.mount('#cardNumber');
    this.cardExpiration.mount('#cardExpiration');
    this.cardSecurityCode.mount('#cardSecurityCode');
  }

  private setIsConfirmingSetupIntent(isConfirmingSetupIntent: boolean): void {
    this.isConfirmingSetupIntent = isConfirmingSetupIntent;
    this.changeDetectorRef.markForCheck();
  }

  private validateSetupIntent(setupIntentResult: SetupIntentResult): boolean {
    if (!setupIntentResult.setupIntent) {
      if (!Object.values(StripeErrorCodes).includes(setupIntentResult.error.code as StripeErrorCodes)) {
        logger.error(`StripeCreditCardFormComponent: setupIntent is not defined - ${setupIntentResult.error.code}`, null, {
          type: setupIntentResult.error.type,
          charge: setupIntentResult.error.charge,
          code: setupIntentResult.error.code,
          decline_code: setupIntentResult.error.decline_code,
          doc_url: setupIntentResult.error.doc_url,
          payment_method_id: setupIntentResult.error.payment_method?.id,
          setup_intent_id: setupIntentResult.error.setup_intent?.id,
        });
      }
      return false;
    }

    if (typeof setupIntentResult.setupIntent.payment_method !== 'string') {
      logger.error('StripeCreditCardFormComponent: setupIntent.payment_method is not a string', null, {
        stripeError: JSON.stringify(setupIntentResult.error),
      });
      return false;
    }

    return true;
  }

  private createStripeElements(elements: StripeElements): void {
    const style = {
      base: {
        color: '#0e2b42',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '17px',
        '::placeholder': {
          color: 'rgba(14, 43, 66, 0.3)',
        },
      },
      invalid: {
        color: '#e33b38',
        iconColor: '#e33b38',
      },
    };
    this.cardNumber = elements.create('cardNumber', { style });
    this.cardExpiration = elements.create('cardExpiry', { style });
    this.cardSecurityCode = elements.create('cardCvc', { style });
  }

  private attachEventHandlers(): void {
    this.cardNumber.on('change', this.updateCardError.bind(this, CreditCardElement.CardNumber));
    this.cardExpiration.on('change', this.updateCardError.bind(this, CreditCardElement.CardExpiry));
    this.cardSecurityCode.on('change', this.updateCardError.bind(this, CreditCardElement.CardCvc));
  }

  private updateCardError(element: CreditCardElement, event: StripeElementChangeEvent): void {
    this.errors[element] = event.error ? event.error.message : '';
    if ((event as StripeCardNumberElementChangeEvent).brand) {
      this.selectedCardType = (event as StripeCardNumberElementChangeEvent).brand;
    }
    this.changeDetectorRef.markForCheck();
  }
}
