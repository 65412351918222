import { InteractionType } from './interaction-type.enum';

export interface UserInteractionParams {
  interactionType: InteractionType;
  name: string;
  interactionData?: any;
  placement?: string;
  eventName?: string;
  interactionTimestamp?: number;
  abTestVariant?: string;
  excludeEventName?: boolean;
}

export interface UserInteractionEvent {
  interaction_type: InteractionType;
  placement: string;
  name: string;
  interaction_data: any;
  interaction_timestamp: number;
  tracking_id: string;
  portal_ab_test_variant_id?: string;
}

export const eventNamePattern = /^[\d A-Za-z-]{5,150}$/;
