const { QueryParams } = require('../../app/core/models/query-params.enum');
const { SessionStorageParams } = require('../../app/core/models/session-storage-params.enum');

module.exports = (trackingUrl) => {
  try {
    let hidden;
    let visibilityChange;
    let trackingLoaded;
    window.NI_NAMESPACE = window.NI_NAMESPACE || {};
    if (
      document.cookie.includes('mobile-app-webview=true') ||
      window.location.href.includes(QueryParams.ShouldKeepTrackingId) ||
      (sessionStorage.getItem(SessionStorageParams.ShouldKeepTrackingId) &&
        (document.cookie.includes('; tracking-id=') || document.cookie.includes('; test-tracking-id=')))
    ) {
      return;
    }

    const loadTrackingJS = function () {
      window.NI_NAMESPACE.pageLoadCookies = document.cookie || '';
      const f = function (b, c) {
        c || (c = window.location.href);
        b = b.replace(/[\[\]]/g, '\\$&');
        const d = new RegExp(`[?&]${b}(=([^&#]*)|&|#|$)`).exec(c);
        return d ? (d[2] ? decodeURIComponent(d[2].replace(/\+/g, ' ')) : '') : null;
      };
      const i = function (ref) {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.setAttribute('ni_ts', true);
        s.src =
          `${trackingUrl}/tracking.js` + `?ref=${encodeURIComponent(ref || '')}&url=${encodeURIComponent(window.location.href || '')}`;
        const x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      i(f('ni_referer') || document.referrer || 'direct');
    };
    const visibilityEventListener = function () {
      if (!document[hidden] && !trackingLoaded) {
        loadTrackingJS();
        trackingLoaded = true;
        document.removeEventListener(visibilityChange, visibilityEventListener, false);
        console.log('Document became visible!');
      }
    };
    if (typeof document.hidden !== 'undefined') {
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }
    if (typeof document[hidden] === 'undefined') {
      console.log('Visibility api not supported.');
      loadTrackingJS();
      trackingLoaded = true;
    } else if (!document[hidden]) {
      console.log('Document is visible.');
      loadTrackingJS();
      trackingLoaded = true;
    } else {
      document.addEventListener(visibilityChange, visibilityEventListener, false);
    }
  } catch (e) {
    console.error('Tracking script init has failed.', e);
  }
};
