import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../store';
import { HolidayTheme } from '../models/holiday-themes.model';
import { coreActions } from '../store/core.actions';
import { CommonConfigDataService } from './common-config.data.service';

@Injectable({
  providedIn: 'root',
})
export class HolidayThemeService {
  private store = inject(Store<AppState>);
  private commonConfigDataService = inject(CommonConfigDataService);

  loadHolidayTheme(): Observable<boolean> {
    return this.commonConfigDataService.getHolidayTheme().pipe(
      map((holidayTheme: HolidayTheme) => {
        this.store.dispatch(coreActions.setHolidayTheme({ holidayTheme }));
        return true;
      }),
      catchErrorAndLog(() => {
        this.store.dispatch(coreActions.setHolidayTheme({ holidayTheme: null }));
        return of(false);
      }),
    );
  }
}
