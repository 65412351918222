import { Action, createReducer, on } from '@ngrx/store';

import { CancellationReasons } from '../models/cancellation-reasons.model';
import { cancellationActions } from './cancellation.actions';

export interface CancellationState {
  reasons: CancellationReasons;
  isLoading: boolean;
}

export const cancellationInitialState: CancellationState = {
  reasons: {},
  isLoading: false,
};

const reducer = createReducer(
  cancellationInitialState,
  on(cancellationActions.cancelPolicyOld, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(cancellationActions.cancelPolicySucceed, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(cancellationActions.cancelPolicyFailed, (state) => ({
    ...state,
    isLoading: false,
  })),
);

export function cancellationReducer(state: CancellationState, action: Action): CancellationState {
  return reducer(state, action);
}
